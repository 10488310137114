<script setup lang="ts">
import telegram from '~/assets/image/vip/telegram.png'
import { PersonalApi } from '~/net/apis/personal_api'

const onClickLeft = () => history.back()
const list = ref<any[]>([])
async function getList() {
  try {
    const res = await PersonalApi.contactList()
    if (res.code === 200) {
      list.value = res.data as any[]
      console.log(list.value, res.data)
    }
  } catch (e) {}
}
await getList()
</script>

<template>
  <div h-screen>
    <van-nav-bar title="我的交流群" left-arrow @click-left="onClickLeft" />
    <div v-for="(item, index) in list" :key="index" px-15px>
      <a :href="item.targetUrl">
         <imgWrapper h-100px :src="item.pictureUrl" placeholder="/343_126.jpg" />
      </a>
    </div>
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
</style>
